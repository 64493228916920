// src/pages/Admin/AddCategory.js
import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Paper } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddCategory = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

const handleSubmit = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem('authToken');

    try {
        await axios.post('https://api.mosque.sites.hostilna.com/categories', { name, description }, {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        });
        navigate('/admin/categories'); // Redirection vers la liste des catégories après ajout
    } catch (error) {
        setError('حدثت مشكلة أثناء إضافة الفئة. يرجى المحاولة مرة أخرى.');
    }
};

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: 20 }}>
        <Typography variant="h5" gutterBottom>
          إضافة فئة جديدة
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="الاسم"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="الوصف"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
          >
            إضافة
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AddCategory;

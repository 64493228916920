import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Container, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditBook = () => {
    const { id } = useParams(); // Récupérer l'ID du livre depuis les paramètres de l'URL
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState('');
    const [author, setAuthor] = useState('');
    const [publishingHouse, setPublishingHouse] = useState('');
    const [year, setYear] = useState('');
    const [isbn, setIsbn] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const [picture, setPicture] = useState(null);
    const [currentPicture, setCurrentPicture] = useState(''); // Pour afficher l'image actuelle
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://api.mosque.sites.hostilna.com/categories');
                setCategories(response.data.data || []);
            } catch (error) {
                setError('Erreur lors de la récupération des catégories');
            }
        };

        const fetchBook = async () => {
            try {
                const response = await axios.get(`https://api.mosque.sites.hostilna.com/books/${id}`);
                const book = response.data;
                setTitle(book.title || '');
                setDescription(book.description || '');
                setQuantity(book.quantity || '');
                setAuthor(book.author || '');
                setPublishingHouse(book.publishing_house || '');
                setYear(book.year || '');
                setIsbn(book.isbn || '');
                setCategoryId(book.category_id || '');
                setCurrentPicture(book.picture || ''); // Mettre à jour l'image actuelle
            } catch (error) {
                setError('Erreur lors de la récupération du livre');
            }
        };

        fetchCategories();
        fetchBook();
    }, [id]);

    const handleFileChange = (event) => {
        setPicture(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const authToken = localStorage.getItem('authToken');
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('quantity', quantity);
        formData.append('author', author);
        formData.append('publishing_house', publishingHouse);
        formData.append('year', year);
        formData.append('isbn', isbn);
        formData.append('category_id', categoryId);
        if (picture) formData.append('picture', picture); // Ajouter l'image seulement si elle est modifiée

        try {
            await axios.put(`https://api.mosque.sites.hostilna.com/books/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authToken}` // En-tête Authorization avec Bearer Token
                },
            });
            navigate('/admin/books');
        } catch (error) {
            setError('Erreur lors de la mise à jour du livre');
        }
    };

    return (
        <Container>
            <Typography variant="h4">Éditer Livre</Typography>
            {error && <Typography color="error">{error}</Typography>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Titre"
                    fullWidth
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    label="Description"
                    fullWidth
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                    label="Quantité"
                    fullWidth
                    margin="normal"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
                <TextField
                    label="Auteur"
                    fullWidth
                    margin="normal"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                />
                <TextField
                    label="Maison d'édition"
                    fullWidth
                    margin="normal"
                    value={publishingHouse}
                    onChange={(e) => setPublishingHouse(e.target.value)}
                />
                <TextField
                    label="Année"
                    fullWidth
                    margin="normal"
                    type="number"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />
                <TextField
                    label="ISBN"
                    fullWidth
                    margin="normal"
                    value={isbn}
                    onChange={(e) => setIsbn(e.target.value)}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Catégorie</InputLabel>
                    <Select
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.target.value)}
                        label="Catégorie"
                    >
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {currentPicture && (
                    <div>
                        <Typography variant="body2">Image actuelle :</Typography>
                        <img src={`https://api.mosque.sites.hostilna.com/public/books/${currentPicture}`} alt="Livre" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                    </div>
                )}
                <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    onChange={handleFileChange}
                />
                <Button type="submit" variant="contained" color="primary">Sauvegarder</Button>
            </form>
        </Container>
    );
};

export default EditBook;

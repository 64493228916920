import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logoImg from '../../images/logo.png';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';

const URL_CATEGORIES = 'https://api.mosque.sites.hostilna.com/categories'; // Assurez-vous que l'URL est correcte

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [categories, setCategories] = useState([]);
  
  // Fetch categories from the API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(URL_CATEGORIES);
        const data = await response.json();
        setCategories(data.data || []); // Assurez-vous que la clé `categories` est correcte
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleNavbar = () => setToggleMenu(!toggleMenu);

  return (
    <nav className='navbar' id='navbar'>
      <div className='container navbar-content flex'>
        <div className='brand-and-toggler flex flex-sb'>
          <Link to='/' className='navbar-brand flex'>
            <img src={logoImg} alt='site logo' />
            <span className='mosque'>مسجد علي بن ابي طالب</span>
          </Link>
          <button type='button' className='navbar-toggler-btn' onClick={handleNavbar}>
            <HiOutlineMenuAlt3 size={35} style={{ color: `${toggleMenu ? '#fff' : '#010101'}` }} />
          </button>
        </div>

        <div className={toggleMenu ? 'navbar-collapse show-navbar-collapse' : 'navbar-collapse'}>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <Link to='book' className='nav-link text-uppercase text-white fs-22 fw-6 ls-1'>الكتب</Link>
            </li>
            <li className='nav-item'>
              <Link to='about' className='nav-link text-uppercase text-white fs-22 fw-6 ls-1'>عنّا</Link>
            </li>
            <li className='nav-item dropdown'>
              <button className='nav-link text-uppercase text-white fs-22 fw-6 ls-1 dropdown-btn'>
                الفئات
                <span className='dropdown-arrow'>▼</span>
              </button>
              <ul className='dropdown-menu'>
                <li className='dropdown-item'>
                  <a href='/' className='text-white'>الكل</a>
                </li>
                {categories.map((category) => (
                  <li key={category.id} className='dropdown-item'>
                    <Link to={`/category/${category.id}`} className='text-white'>
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

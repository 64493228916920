// src/App.js
import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppProvider } from './context.';
import { ColorModeContext, useMode } from './theme';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import BookList from './components/BookList/BookList';
import BookDetails from './components/BookDetails/BookDetails';
import AdminLogin from './pages/Admin/AdminLogin';
import Categories from './pages/Admin/Categories';
import Layout from './components/Layout/Layout';
import PrivateRoute from './components/PrivateRoute';
import AddCategory from './pages/Admin/AddCategory'; // Importation du nouveau composant
import EditCategory from './pages/Admin/EditCategory'; // Importation du composant d'édition
import Booklist from './pages/Admin/Booklist';
import AddBook from './pages/Admin/AddBook';
import EditBook from './pages/Admin/EditBook';
import AdminList from './pages/Admin/AdminList';
import AddAdmin from './pages/Admin/AddAdmin';
import Logout from './pages/Admin/Logout';
import Category from './components/BookList/Category';

const App = () => {
  const [theme, colorMode] = useMode();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          {/* Routes avec Home layout */}
          <Route element={<Home />}>
            <Route path="/" element={<BookList />} />
            <Route path="about" element={<About />} />
            <Route path="book" element={<BookList />} />
            <Route path="book/:id" element={<BookDetails />} />
            <Route path="category/:id" element={<Category />} />
          </Route>

          {/* Routes Admin protégées */}
          <Route element={<PrivateRoute element={<Layout />} />}>
            <Route path="/admin/categories" element={<Categories />} />
            <Route path="/admin/categories/add" element={<AddCategory />} /> {/* Nouvelle route */}
            <Route path="/admin/categories/edit/:id" element={<EditCategory />} /> {/* Nouvelle route d'édition */}

            <Route path="/admin/books" element={<Booklist />} />
            <Route path="/admin/books/add" element={<AddBook />} />
            <Route path="/admin/books/edit/:id" element={<EditBook />} />
            <Route path="/admin/admins" element={<AdminList />} />
            <Route path="/admin/admins/add" element={<AddAdmin />} />
            <Route path="/admin/logout" element={<Logout />} />

          </Route>

          {/* Routes pour la connexion admin */}
          <Route path="/admin/login" element={<AdminLogin />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;

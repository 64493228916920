import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Container, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddBook = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState('');
    const [author, setAuthor] = useState('');
    const [publishingHouse, setPublishingHouse] = useState('');
    const [year, setYear] = useState('');
    const [isbn, setIsbn] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);
    const [picture, setPicture] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://api.mosque.sites.hostilna.com/categories');
                setCategories(response.data.data || []);
            } catch (error) {
                setError('حدث خطأ أثناء استرداد الفئات');
            }
        };

        fetchCategories();
    }, []);

    const handleFileChange = (event) => {
        setPicture(event.target.files[0]);
    };
    const handleSubmit = async (event) => {
        
        event.preventDefault();
        const authToken = localStorage.getItem('authToken')
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('quantity', quantity);
        formData.append('author', author);
        formData.append('publishing_house', publishingHouse);
        formData.append('year', year);
        formData.append('isbn', isbn);
        formData.append('category_id', categoryId);
        if (picture) formData.append('picture', picture);
    
        try {
          ;
            await axios.post('https://api.mosque.sites.hostilna.com/books', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${authToken}` // En-tête Authorization avec Bearer Token
                },
            });
            navigate('/admin/books');
        } catch (error) {
            setError('Erreur lors de la création du livre');
        }
    };
    

    return (
        <Container>
            <Typography variant="h4">إضافة كتاب</Typography>
            {error && <Typography color="error">{error}</Typography>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="العنوان"
                    fullWidth
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    label="الوصف"
                    fullWidth
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                    label="الكمية"
                    fullWidth
                    margin="normal"
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                />
                <TextField
                    label="المؤلف"
                    fullWidth
                    margin="normal"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                />
                <TextField
                    label="دار النشر"
                    fullWidth
                    margin="normal"
                    value={publishingHouse}
                    onChange={(e) => setPublishingHouse(e.target.value)}
                />
                <TextField
                    label="السنة"
                    fullWidth
                    margin="normal"
                    type="number"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                />
                <TextField
                    label="رقم ISBN"
                    fullWidth
                    margin="normal"
                    value={isbn}
                    onChange={(e) => setIsbn(e.target.value)}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>الفئة</InputLabel>
                    <Select
                        value={categoryId}
                        onChange={(e) => setCategoryId(e.target.value)}
                        label="الفئة"
                    >
                        {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    onChange={handleFileChange}
                />
                <Button type="submit" variant="contained" color="primary">إضافة</Button>
            </form>
        </Container>
    );
};

export default AddBook;

// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const isAuthenticated = !!localStorage.getItem('authToken'); // Vérifiez l'authentification ici

  return isAuthenticated ? element : <Navigate to="/admin/login" />;
};

export default PrivateRoute;

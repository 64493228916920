// src/pages/Admin/Categories.js
import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Typography, Button } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const response = await axios.get('https://api.mosque.sites.hostilna.com/categories', {
                    params: {
                        page: page + 1,
                        limit: rowsPerPage
                    }
                });
                setCategories(response.data.data || []);
                setTotal(response.data.total || 0);
            } catch (error) {
                setError('حدث خطأ أثناء استرداد الفئات');
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://api.mosque.sites.hostilna.com/categories/${id}`);
            setCategories(categories.filter(category => category.id !== id));
        } catch (error) {
            setError('حدث خطأ أثناء حذف الفئة');
        }
    };

    if (loading) {
        return <Typography variant="h6">جارٍ التحميل...</Typography>;
    }

    if (error) {
        return <Typography variant="h6" color="error">{error}</Typography>;
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                قائمة الفئات
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>الاسم</TableCell>
                            <TableCell>الوصف</TableCell>
                            <TableCell>الإجراءات</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {categories.length > 0 ? (
                            categories.map((category) => (
                                <TableRow key={category.id}>
                                    <TableCell>{category.name}</TableCell>
                                    <TableCell>{category.description}</TableCell>
                                    <TableCell>
                                        <Button
                                            component={Link}
                                            to={`/admin/categories/edit/${category.id}`}
                                            variant="contained"
                                            color="primary"
                                        >
                                            تعديل
                                        </Button>
                                        <Button
                                            onClick={() => handleDelete(category.id)}
                                            variant="contained"
                                            color="error"
                                            style={{ marginLeft: 8 }}
                                        >
                                            حذف
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={3}>لم يتم العثور على أي فئة</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};

export default Categories;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Typography, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

const AdminsList = () => {
    const [admins, setAdmins] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const response = await axios.get('https://api.mosque.sites.hostilna.com/admins');
                setAdmins(response.data.data || []);
            } catch (error) {
                setError('Erreur lors de la récupération des administrateurs');
            }
        };

        fetchAdmins();
    }, []);

    return (
        <Container>
            <Typography variant="h4">Liste des Administrateurs</Typography>
            {error && <Typography color="error">{error}</Typography>}
            <Button component={Link} to="/admin/add" variant="contained" color="primary" style={{ marginBottom: '20px' }}>
                Ajouter un Administrateur
            </Button>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {admins.map((admin) => (
                            <TableRow key={admin.id}>
                                <TableCell>{admin.name}</TableCell>
                                <TableCell>{admin.email}</TableCell>
                                <TableCell>
                                    {/* Vous pouvez ajouter des actions comme éditer ou supprimer ici */}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default AdminsList;

import React, { useState, useCallback, useEffect, useContext } from 'react';
import './index.css';

const URL = "https://api.mosque.sites.hostilna.com/books";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [category, setCategory] = useState("");
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [resultTitle, setResultTitle] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalBooks, setTotalBooks] = useState(0);

    const fetchBooks = useCallback(async () => {
        setLoading(true);
        let url;
        if (category) {
            url = `${URL}?s=${searchTerm}&page=${currentPage}&limit=10&category=${category}`;
        } else {
            url = `${URL}?s=${searchTerm}&page=${currentPage}&limit=10`;
        }
        try {
            const response = await fetch(url);
            const data = await response.json();
            setBooks(data.books);
            setTotalPages(data.totalPages);
            setTotalBooks(data.totalBooks);
            setResultTitle(data.books.length > 0 ? "نتيجة البحث" : "لا يوجد نتيجة للبحث");
        } catch (error) {
            console.log(error);
            setResultTitle("Erreur lors de la récupération des livres");
        }
        setLoading(false);
    }, [searchTerm, currentPage, category]);

    useEffect(() => {
    // if /book this, if /book/:id add category
        fetchBooks();
    }, [searchTerm, currentPage, fetchBooks, category]);

    return (
        <AppContext.Provider value={{
            loading, books, setSearchTerm, resultTitle, currentPage, setCurrentPage, totalPages, setCategory
        }}>
            {children}
        </AppContext.Provider>
    );
};

export const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppContext, AppProvider };

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container } from '@mui/material';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Supprimer le token d'authentification du localStorage
        localStorage.removeItem('authToken');

        // Rediriger l'utilisateur vers la page d'accueil après la déconnexion
        navigate('/');
    }, [navigate]);

    return (
        <Container>
            <Typography variant="h4">Déconnexion en cours...</Typography>
            <Button variant="contained" color="primary" onClick={() => navigate('/')}>
                Retour à l'accueil
            </Button>
        </Container>
    );
};

export default Logout;

// src/components/Layout/Layout.js
import React from 'react';
import Sidebar from '../Admin/Sidebar';
import { Outlet } from 'react-router-dom';
import './Layout.css'; // Importez votre fichier CSS pour la mise en page

const Layout = () => {
  return (
    <div className="layout">
      <Sidebar />
      <div className="content">
        <Outlet /> {/* Ceci affichera le contenu des routes enfants */}
      </div>
    </div>
  );
};

export default Layout;

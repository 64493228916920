import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddAdmin = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const authToken = localStorage.getItem('authToken');
        
        try {
            await axios.post('https://api.mosque.sites.hostilna.com/admins', {
                name,
                email,
                password,
            }, {
                headers: {
                    'Authorization': `Bearer ${authToken}` // En-tête Authorization avec Bearer Token
                },
            });
            navigate('/admin/admins');
        } catch (error) {
            setError('Erreur lors de l\'ajout de l\'administrateur');
        }
    };

    return (
        <Container>
            <Typography variant="h4">Ajouter un Administrateur</Typography>
            {error && <Typography color="error">{error}</Typography>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Nom"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    label="Email"
                    fullWidth
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    label="Mot de passe"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="submit" variant="contained" color="primary">Ajouter</Button>
            </form>
        </Container>
    );
};

export default AddAdmin;

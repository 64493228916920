import React from 'react';
import LoaderImg from "../../images/loader.svg";
import "./Loader.css";
// lottie-react
import Lottie from "lottie-react";
import animationData from '../Loader.json';

const Loader = () => {
  return (
    <div className='loader flex flex-c'>
    <Lottie 
    style={{width: "300px", height: "100px"}}
    animationData={animationData} loop autoplay />


    </div>
  )
}

export default Loader
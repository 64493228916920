import React from 'react';
import Navbar from "../Navbar/Navbar";
import SearchForm from "../SearchForm/SearchForm";
import "./Header.css";

const Header = () => {
  return (
    <div className='holder'>
 
        <header className='header'>
            <Navbar />
            <div className='header-content flex flex-c text-center text-white'>
                <h2 className='header-title'>
                مسجد علي بن ابي طالب
                  </h2><br />
                <p className='header-text fs-18 fw-3'>
                مكتبة الكترونية للكتب الدينية, يمكنك البحث عن الكتب وقراءتها مجاناً
                </p>
                <SearchForm />
            </div>
        </header>
    </div>
  )
}

export default Header
import React from 'react';
import "./About.css";
import aboutImg from "../../images/library-img.jpg";

const About = () => {
  return (
    <section className='about'>
      <div className='container'>
        <div className='section-title'>
          <h2>عنّا</h2>
        </div>

        <div className='about-content grid'>
          <div className='about-img'>
            <img src = {aboutImg} alt = "" />
          </div>
          <div className='about-text'>
            <h2 className='about-title fs-26 ls-1'>
              
              عن مسجد علي بن أبي طالب ببوفاريك
            </h2>
            <p className='fs-17'>مسجد علي بن أبي طالب هو معلم ديني بارز يقع في مدينة بوفاريك، الجزائر. يتميز هذا المسجد بتصميمه المعماري الجميل الذي يعكس التراث الإسلامي العريق، ويعكس كذلك الطابع الثقافي للمنطقة.

الموقع: يقع المسجد في قلب مدينة بوفاريك، مما يجعله نقطة مركزية للأهالي والزوار على حد سواء. يُعرف بموقعه الاستراتيجي الذي يسهل الوصول إليه من مختلف أنحاء المدينة.
</p>
            <p className='fs-17'>
التصميم المعماري: يتميز المسجد بتصميمه المعماري التقليدي الذي يجمع بين الأصالة والحداثة. يشمل التصميم قبة كبيرة ومنارة مهيبة، إضافة إلى تفاصيل معمارية تعكس الفن الإسلامي الكلاسيكي. تُضفي الألوان والأنماط التقليدية لمسة جمالية فريدة على الهيكل العام للمسجد.

الأنشطة والخدمات: يقدم المسجد مجموعة من الأنشطة والخدمات للمجتمع المحلي، بما في ذلك الصلوات اليومية، والندوات الدينية، والدروس التعليمية. كما يُعتبر مركزًا هامًا للفعاليات الاجتماعية والثقافية، مما يعزز الروابط بين أفراد المجتمع.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About

// src/AdminLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Admin.css'; // تأكد من وجود ملف الـ CSS في نفس المجلد

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
      try {
        e.preventDefault();
        const response = await fetch('https://api.mosque.sites.hostilna.com/admins/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // cors
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify({
            email,
            password
          })
        });
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem('authToken', data.authToken);
          navigate('/admin/categories');
        } else {
          setError('البريد الإلكتروني أو كلمة المرور غير صحيحة');
        }
      } catch (error) {
        setError('حدث خطأ أثناء تسجيل الدخول');
      }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                    <label>البريد الإلكتروني:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>كلمة المرور:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <p className="error-message">{error}</p>}
                <button type="submit" className="login-button">تسجيل الدخول</button>
            </form>
        </div>
    );
}

export default AdminLogin;
